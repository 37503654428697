import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Loading from 'common/loading/circle'
import fetch from 'components/people/person/actions/fetch'
import Details from 'components/details'
import setPageTitle from 'components/utils/setPageTitle'
import AdminSection from 'components/people/person/admin'
import NamePassword from 'components/people/person/namePassword'
import HomeBuying from 'components/people/person/HomeBuying'
import memberOwner from 'components/people/person/memberOwner'
import SignOutBtn from 'components/people/person/signOutBtn'
import get from 'components/people/person/get'

export class Person extends PureComponent {
  static propTypes = {
    fetch: PropTypes.func.isRequired,
    fetching: PropTypes.bool.isRequired,
    id: PropTypes.string,
    isMemberOwner: PropTypes.bool.isRequired,
  }

  static defaultProps = { id: null }

  // `id` is null in componentDidMount.
  // Also used when clicking a person link while on person link
  // (i.e. admin on a person's page and clicking the account (nav) button)
  componentWillReceiveProps(nextProps) {
    const { fetch, id } = this.props
    if (nextProps.id !== id) {
      fetch(nextProps.id)
    }
  }

  componentWillUnmount() {
    setPageTitle('')
  }

  render() {
    const { fetching, isMemberOwner } = this.props
    if (fetching) {
      return <Loading />
    }

    return (
      <>
        <NamePassword />
        <HomeBuying />
        <Details />
        <SignOutBtn />
        {isMemberOwner && <AdminSection />}
      </>
    )
  }
}

export const mapState = (state, { location }) => {
  let fetching = true
  let isMemberOwner = false
  let person

  let { id } = state.people.person

  if (id) {
    // admin on a person's page and clicking the account (nav) button)
    if (location.pathname === '/me') {
      id = state.auth.id
    }

    isMemberOwner = memberOwner(state, id)
    person = get(state, id)

    if (person) {
      // ensures `fetch` has finished
      if (person.get('details')) {
        fetching = false
      }

      const firstName = person.get('first_name') || ''
      const lastName = person.get('last_name') || ''
      setPageTitle(`${firstName} ${lastName}`)
    }
  }

  return {
    fetching,
    id: state.people.person.id, // so fetch will happen
    isMemberOwner,
  }
}

export default connect(mapState, { fetch })(Person)
