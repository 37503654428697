import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import history from 'components/history'
import get from 'components/people/person/get'

export class HomeBuyingCont extends PureComponent {
  static propTypes = {
    items: PropTypes.shape({
      searches: PropTypes.number.isRequired,
      likes: PropTypes.number.isRequired,
      dislikes: PropTypes.number.isRequired,
    }).isRequired,
  }

  render() {
    const {
      items: { searches, likes, dislikes },
    } = this.props

    const { pathname } = history.location
    const isMember = pathname !== '/me'
    const searchPath = isMember ? `${pathname}/searches` : '/searches'
    const votesPath = isMember ? pathname : ''

    return (
      <h5 style={{ textAlign: 'center' }}>
        <a href={searchPath}>Searches: {searches}</a>
        {' | '}
        <a href={`${votesPath}/likes`}>Likes: {likes}</a>
        {' | '}
        <a href={`${votesPath}/dislikes`}>Dislikes: {dislikes}</a>
      </h5>
    )
  }
}

export const mapState = state => {
  const { id } = state.people.person
  const person = get(state, id)

  return { items: person.get('items').toJS() }
}

export default connect(mapState)(HomeBuyingCont)
